import React from "react";
import theme from "theme";
import { Theme, Text, Icon, Box, List, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaCheckCircle } from "react-icons/fa";
import { MdCheckCircle } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				STARGAZE EVENTS
			</title>
			<meta name={"description"} content={"Добро пожаловать на мероприятия StarGaze"} />
			<meta property={"og:title"} content={"STARGAZE EVENTS"} />
			<meta property={"og:description"} content={"Добро пожаловать на мероприятия StarGaze"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
		</Helmet>
		<Components.Header2>
			<Override slot="text" />
		</Components.Header2>
		<Section
			padding="80px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			background="#fafaf9"
			display="flex"
			quarkly-title="Advantages/Features-28"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="80%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
			>
				<Text
					as="h1"
					margin="0px"
					font="normal 600 40px/1.5 --fontFamily-sans"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
				>
					Полное планирование мероприятия
				</Text>
				<Text
					as="h1"
					margin="1rem 0px 0px 0px"
					font="--headline3"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
				>
					Превращаем мечты в реальность
				</Text>
				<List
					margin="40px 0px 0px 0px"
					padding="0px 0px 0px 0px"
					as="ul"
					sm-margin="24px 0 0 0"
					list-style-type="none"
					font="normal normal 18px/150% sans-serif"
					display="flex"
					flex-direction="column"
					grid-gap="25px"
					lg-margin="32px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCheckCircle}
							size="20px"
							color="#4f46e5"
							margin="5px 0 0 0"
						/>
						<Text
							padding="0"
							margin="0"
							color="--dark"
							font="--lead"
							border-color="--color-darkL1"
						>
							Индивидуальные украшения и стили
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCheckCircle}
							size="20px"
							color="#4f46e5"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--dark" font="--lead">
							Развлечения и мероприятия
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCheckCircle}
							size="20px"
							color="#4f46e5"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" font="--lead" color="--dark">
							Услуги общественного питания
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCheckCircle}
							size="20px"
							color="#4f46e5"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" font="--lead" color="--dark">
							Логистика и координация
						</Text>
					</Box>
				</List>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
			>
				<Image
					src="https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-4.jpg?v=2024-06-10T08:22:40.315Z"
					display="block"
					border-radius="32px"
					srcSet="https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-4.jpg?v=2024-06-10T08%3A22%3A40.315Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-4.jpg?v=2024-06-10T08%3A22%3A40.315Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-4.jpg?v=2024-06-10T08%3A22%3A40.315Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-4.jpg?v=2024-06-10T08%3A22%3A40.315Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-4.jpg?v=2024-06-10T08%3A22%3A40.315Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-4.jpg?v=2024-06-10T08%3A22%3A40.315Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-4.jpg?v=2024-06-10T08%3A22%3A40.315Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section
			padding="80px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			background="#fafaf9"
			display="flex"
			quarkly-title="Advantages/Features-28"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="80%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				order="1"
				align-self="center"
			>
				<Text
					as="h1"
					margin="1rem 0px 0px 0px"
					font="--headline3"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
				>
					Индивидуальные украшения и стили
				</Text>
				<Text
					as="h1"
					margin="1rem 0px 0px 0px"
					font="--base"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
				>
					Наши услуги по индивидуальному декору и стилю гарантируют, что ваше мероприятие отразит ваш уникальный стиль и индивидуальность. От элегантных цветочных композиций до ярких центральных элементов — мы создаем визуально ошеломляющую обстановку, которая оставит неизгладимое впечатление на ваших гостей.
				</Text>
				<List
					margin="40px 0px 0px 0px"
					padding="0px 0px 0px 0px"
					as="ul"
					sm-margin="24px 0 0 0"
					list-style-type="none"
					font="normal normal 18px/150% sans-serif"
					display="flex"
					flex-direction="column"
					grid-gap="25px"
					lg-margin="32px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="md"
							icon={MdCheckCircle}
							size="20px"
							color="#4f46e5"
							margin="5px 0 0 0"
						/>
						<Text
							padding="0"
							margin="0"
							color="--dark"
							font="--base"
							border-color="--color-darkL1"
						>
							Букеты и композиции с цветочным дизайном: красиво оформленные букеты и цветочные композиции, которые добавят элегантности любому событию.
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCheckCircle}
							size="20px"
							color="#4f46e5"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--dark" font="--base">
							Цветочные инсталляции: потрясающие цветочные инсталляции, которые станут идеальным фоном для вашего мероприятия.
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCheckCircle}
							size="20px"
							color="#4f46e5"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" font="--base" color="--dark">
							Центральные элементы: уникальные и элегантные центральные элементы, которые улучшают общий декор.
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCheckCircle}
							size="20px"
							color="#4f46e5"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" font="--base" color="--dark">
							Концептуализация темы стиля мероприятия: креативные темы, которые сделают ваше мероприятие запоминающимся.
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCheckCircle}
							size="20px"
							color="#4f46e5"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" font="--base" color="--dark">
							Канцелярские товары на заказ: канцелярские товары на заказ, соответствующие вашему видению.
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCheckCircle}
							size="20px"
							color="#4f46e5"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" font="--base" color="--dark">
							Световой дизайн: Профессиональное освещение, создающее идеальную атмосферу.
						</Text>
					</Box>
				</List>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
			>
				<Image
					src="https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08:22:40.334Z"
					display="block"
					border-radius="32px"
					srcSet="https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section
			padding="80px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			background="#fafaf9"
			display="flex"
			quarkly-title="Advantages/Features-28"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="80%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
			>
				<Text
					as="h1"
					margin="1rem 0px 0px 0px"
					font="--headline3"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
				>
					Развлечения и мероприятия
				</Text>
				<Text
					as="h1"
					margin="1rem 0px 0px 0px"
					font="--base"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
				>
					Мы предлагаем широкий выбор развлечений, чтобы ваши гости были заняты и развлекались во время вашего мероприятия. От живой музыки до интерактивных мероприятий — мы позаботимся о том, чтобы ваше мероприятие было наполнено весельем и азартом.
				</Text>
				<List
					margin="40px 0px 0px 0px"
					padding="0px 0px 0px 0px"
					as="ul"
					sm-margin="24px 0 0 0"
					list-style-type="none"
					font="normal normal 18px/150% sans-serif"
					display="flex"
					flex-direction="column"
					grid-gap="25px"
					lg-margin="32px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="md"
							icon={MdCheckCircle}
							size="20px"
							color="#4f46e5"
							margin="5px 0 0 0"
						/>
						<Text
							padding="0"
							margin="0"
							color="--dark"
							font="--base"
							border-color="--color-darkL1"
						>
							Живые выступления Группы и диджеи: Талантливые группы и диджеи, которые своей музыкой создают нужную атмосферу.
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCheckCircle}
							size="20px"
							color="#4f46e5"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--dark" font="--base">
							Танцевальные шоу: профессиональные танцоры, которые добавляют элегантности и энергии.
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCheckCircle}
							size="20px"
							color="#4f46e5"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" font="--base" color="--dark">
							Фокусники и артисты: артисты, которые очаровывают ваших гостей своим мастерством.
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCheckCircle}
							size="20px"
							color="#4f46e5"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" font="--base" color="--dark">
							Фотобудки с интерактивными мероприятиями: веселые и креативные фотобудки, которые оставят незабываемые воспоминания.
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCheckCircle}
							size="20px"
							color="#4f46e5"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" font="--base" color="--dark">
							Игры и конкурсы: интерактивные игры и конкурсы, которые привлекут ваших гостей.
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCheckCircle}
							size="20px"
							color="#4f46e5"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" font="--base" color="--dark">
							Семинары и уроки: образовательные и веселые мастер-классы, предлагающие уникальные впечатления.
						</Text>
					</Box>
				</List>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
			>
				<Image
					src="https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-3.jpg?v=2024-06-10T08:22:40.314Z"
					display="block"
					border-radius="32px"
					srcSet="https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-3.jpg?v=2024-06-10T08%3A22%3A40.314Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-3.jpg?v=2024-06-10T08%3A22%3A40.314Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-3.jpg?v=2024-06-10T08%3A22%3A40.314Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-3.jpg?v=2024-06-10T08%3A22%3A40.314Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-3.jpg?v=2024-06-10T08%3A22%3A40.314Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-3.jpg?v=2024-06-10T08%3A22%3A40.314Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-3.jpg?v=2024-06-10T08%3A22%3A40.314Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section
			padding="80px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			background="#fafaf9"
			display="flex"
			quarkly-title="Advantages/Features-28"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="80%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				order="1"
				align-self="center"
			>
				<Text
					as="h1"
					margin="1rem 0px 0px 0px"
					font="--headline3"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
				>
					Услуги общественного питания
				</Text>
				<Text
					as="h1"
					margin="1rem 0px 0px 0px"
					font="--base"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
				>
					Наши кейтеринговые службы предлагают вкусные, красиво оформленные блюда и напитки, которые порадуют ваших гостей. Мы сотрудничаем с лучшими рестораторами, чтобы предложить разнообразные варианты меню, удовлетворяющие любые вкусы и диетические потребности.
				</Text>
				<Text
					as="h1"
					margin="1rem 0px 0px 0px"
					font="--headline3"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
				>
					Логистика и координация
				</Text>
				<Text
					as="h1"
					margin="1rem 0px 0px 0px"
					font="--base"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
				>
					Мы управляем всеми логистическими аспектами вашего мероприятия, чтобы обеспечить бесперебойную работу от начала до конца. Наши услуги по координации охватывают все: от управления поставщиками до контроля на месте, чтобы вы могли сосредоточиться на получении удовольствия от вашего мероприятия.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
			>
				<Image
					src="https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-1.jpg?v=2024-06-10T08:22:40.311Z"
					display="block"
					border-radius="32px"
					srcSet="https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-1.jpg?v=2024-06-10T08%3A22%3A40.311Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-1.jpg?v=2024-06-10T08%3A22%3A40.311Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-1.jpg?v=2024-06-10T08%3A22%3A40.311Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-1.jpg?v=2024-06-10T08%3A22%3A40.311Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-1.jpg?v=2024-06-10T08%3A22%3A40.311Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-1.jpg?v=2024-06-10T08%3A22%3A40.311Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-1.jpg?v=2024-06-10T08%3A22%3A40.311Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text4">
				Пн - Пт (10:00 - 21:00)
			</Override>
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bb720efdcae00021dd9fe2"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});